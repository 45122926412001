import ReactInputMask from 'react-input-mask'
import type { FieldError, UseFormRegister } from 'react-hook-form'
import { ErrorIcon } from 'src/components/Icons/ErrorIcon'

import './textInput.scss'

interface TextInputProps {
  label: string | undefined
  name: keyof {
    firstName: string
    lastName: string
    cpf: string
    phone: string
  }
  type: string | undefined
  placeholder: string | undefined
  mask: string | undefined
  errors: FieldError | undefined
  register: UseFormRegister<{
    firstName: string
    lastName: string
    cpf: string
    phone: string
    terms: true
    newsletter: boolean
  }>
}

export function TextInput(props: TextInputProps) {
  return (
    <div className="textInputWrapper font-inter w-full flex flex-wrap relative">
      <label className="font-normal text-sm">{props.label}</label>

      {props.mask !== '' && (
        <ReactInputMask
          mask={props.mask ?? ''}
          className="textInput text-black text-sm"
          placeholder={props.placeholder}
          {...props.register(props.name, {
            setValueAs: (value) =>
              value.replace(/[`~!@#$%^&*()_|\-=?;:'",.<>{}[\]\\/\s]/gi, ''),
          })}
          aria-invalid={!!props.errors}
        />
      )}

      {props.mask === '' && (
        <input
          className="textInput text-black text-sm"
          placeholder={props.placeholder}
          {...props.register(props.name)}
          aria-invalid={!!props.errors}
        />
      )}

      {props.errors && (
        <span className="textInputError mt-1">
          <ErrorIcon /> {props.errors.message}
        </span>
      )}
    </div>
  )
}
