import { useMobile } from 'src/hooks/useMobile'
import Logo from 'src/components/ui/Logo'

import ArrowBack from './BackIcon'

export function Header() {
  const { isMobile } = useMobile()

  function handleClick() {
    window.close()
  }

  return (
    <header className="py-3 px-5 sm:py-6 sm:px-16 h-[86px]">
      <div className="flex items-center justify-center h-full">
        <button
          onClick={() => handleClick()}
          className="back-action flex items-center flex-1"
          title="Voltar"
        >
          <ArrowBack />{' '}
          <span className="font-inter text-sm font-medium ml-2 hidden sm:block">
            Voltar
          </span>
        </button>
        <div className="logo flex justify-center flex-1">
          <Logo
            bgFill="#3643ba"
            width={isMobile ? 147 : 180}
            height={isMobile ? 22 : 27}
          />
        </div>
        <div className="flex-1" />
      </div>
    </header>
  )
}
