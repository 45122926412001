import type { ReactNode } from 'react'
import { ErrorIcon } from 'src/components/Icons/ErrorIcon'
import type { FieldError, UseFormRegister } from 'react-hook-form'

import './checkboxInput.scss'

interface CheckboxInputProps {
  style?: Record<string, unknown>
  errors?: FieldError
  children: ReactNode
  defaultChecked?: boolean
  name: 'terms' | 'newsletter'
  register: UseFormRegister<{
    firstName: string
    lastName: string
    cpf: string
    phone: string
    terms: true
    newsletter: boolean
  }>
}

export function CheckboxInput(props: CheckboxInputProps) {
  return (
    <label
      style={props.style}
      htmlFor={props.name}
      className={`inputCheckbox ${props.errors ? 'error' : ''}`}
    >
      {props.children}
      <input
        id={props.name}
        type="checkbox"
        defaultChecked={props.defaultChecked}
        {...props.register(props.name)}
      />
      <span className="checkmark w-[18px] h-[18px] rounded-[2px]" />

      {props.errors && (
        <span className="messageError font-inter">
          <ErrorIcon /> {props.errors.message}
        </span>
      )}
    </label>
  )
}
