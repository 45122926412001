export default function ArrowBack() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.87723 8.74838L7.26357 12.1347L6.20291 13.1954L1.00591 7.99838L6.2029 2.80138L7.26356 3.86204L3.87723 7.24838L14.3999 7.24838L14.3999 8.74838L3.87723 8.74838Z"
        fill="#101010"
      />
    </svg>
  )
}
